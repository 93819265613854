import React, { useRef, useState, useMemo, useEffect } from 'react'
import {
  Box,
  MoreBar,
  Group,
  Button,
  Input,
  Text,
  Color,
  StatusPopup,
  Icon,
  IconButton,
  Token,
  Header,
  VStack,
  Cell,
  H6,
  Skeleton,
  HStack,
  Link,
  ActionButton,
  ButtonSkeleton,
  List,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import uniq from 'lodash/uniq'
import lowerFirst from 'lodash/lowerFirst'
import getStartOfMonth from 'date-fns/startOfMonth'
import getEndOfMonth from 'date-fns/endOfMonth'
import pluralize from 'pluralize'
import { ArrowSend, CrossSmall, Document } from '@revolut/icons'

import {
  approveTimeOffRequest,
  cancelTimeOffRequest,
  employeeTimeOffRequests,
  getTimeOffRequestCommentsAPI,
  rejectTimeOffRequest,
  useGetTimeOffPolicy,
  useNextTimeOffRequest,
  useTimeOffOverlappingRequests,
  useTimeOffRequestApprovals,
} from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EmployeeTimeOffRequestInterface,
  TimeOffPolicyInterface,
} from '@src/interfaces/timeOff'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApproveButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { formatDate, formatToOrdinal } from '@src/utils/format'
import { FileInterface } from '@src/interfaces/files'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { RouteParams } from '.'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { utcToLocalDate } from '@src/utils/timezones'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { formatRequestDate } from '@src/constants/columns/timeOff'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { pathToUrl } from '@src/utils/router'
import {
  canApproveEmployeeTimeOffRequest,
  canCancelEmployeeTimeOffRequest,
  canEditEmployeeTimeOffRequest,
  canRejectEmployeeTimeOffRequest,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import SideBar from '@components/SideBar/SideBar'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { policyCategoryToIcon } from '@src/features/TimeOffCalendarTable/helpers'

const unitToLabel = {
  day: 'Day',
  hour: 'Hour',
}

const getCalendarPeriodQueryParams = (date?: string) =>
  date
    ? {
        start_date: formatDate(getStartOfMonth(new Date(date)), 'yyyy-MM-dd'),
        end_date: formatDate(getEndOfMonth(new Date(date)), 'yyyy-MM-dd'),
      }
    : undefined

const getAccrualPeriodInfoString = (policy: TimeOffPolicyInterface) => {
  if (!policy.accrual_period) {
    return ''
  }
  let renewalPeriod = ''
  if (policy.accrual_period?.id === 'daily') {
    renewalPeriod = 'every day'
  } else if (policy.accrual_period?.id === 'weekly') {
    renewalPeriod = `every week on ${policy.accrual_period_day_of_week?.name}`
  } else if (policy.accrual_period?.id === 'monthly') {
    renewalPeriod = `every month on ${formatToOrdinal(
      policy.accrual_period_day_of_month || 0,
    )} day`
  } else if (policy.accrual_period?.id === 'yearly') {
    renewalPeriod = `every year on ${formatToOrdinal(
      policy.accrual_period_day_of_month || 0,
    )} of ${policy.accrual_period_month?.name}`
  } else if (policy.accrual_period?.id === 'anniversary') {
    renewalPeriod = 'anniversary'
  }
  return `Employees earn ${pluralize(
    lowerFirst(policy.unit.name),
    policy.accrual_amount || undefined,
    true,
  )} ${renewalPeriod}`
}

const getBalanceClosingPeriodInfoString = (policy: TimeOffPolicyInterface) => {
  let closingPeriod = ''

  if (policy.balance_closing_period.id === 'anniversary') {
    closingPeriod = `every year on ${lowerFirst(policy.balance_closing_period.name)}`
  } else if (policy.balance_closing_period.id === 'yearly') {
    closingPeriod = `every year on ${formatToOrdinal(
      policy.balance_closing_period_day_of_month || 0,
    )} of ${policy.balance_closing_period_month?.name}`
  } else {
    closingPeriod = `every month on ${formatToOrdinal(
      policy.balance_closing_period_day_of_month || 0,
    )} day`
  }

  return `Balance closes ${closingPeriod}`
}

const getCarryOverInfoString = (policy: TimeOffPolicyInterface) => {
  if (policy.carryover_limit?.id === 'no_carryover') {
    return 'There is no carry over'
  }
  if (policy.carryover_limit?.id === 'unlimited_carryover') {
    return 'Carry over is unlimited and does not expire'
  }
  if (
    policy.carryover_amount &&
    policy.carryover_limit?.id === 'limited_number_of_days'
  ) {
    return `Carry over is limited to ${pluralize('day', policy.carryover_amount, true)}`
  }
  if (
    policy.carryover_amount &&
    policy.carryover_limit?.id === 'limited_number_of_hours'
  ) {
    return `Carry over is limited to ${pluralize('hour', policy.carryover_amount, true)}`
  }
  return ''
}

const getBalanceRoundingLogicInfoString = (policy: TimeOffPolicyInterface) => {
  const roundLogic = policy.balance_rounding_logic
    ? {
        floor: 'floored',
        round: 'rounded',
        ceil: 'ceiled',
      }[policy.balance_rounding_logic.id]
    : ''
  const precision = policy.balance_rounding_precision
    ? {
        integer: 'to the nearest integer',
        half: 'to the nearest half',
        one_decimal: 'to one decimal',
        two_decimal: 'to two decimals',
      }[policy.balance_rounding_precision.id]
    : ''

  return roundLogic && precision ? `Balance is ${roundLogic} ${precision}` : ''
}

type Props = {
  setCategoryName: (name: string) => void
}
const Preview = ({ setCategoryName }: Props) => {
  const queryClient = useQueryClient()
  const params = useParams<{ id: string; employeeId: string }>()
  const { employeeId, id } = useParams<RouteParams>()
  const { values, initialValues, reset } =
    useLapeContext<EmployeeTimeOffRequestInterface>()

  const [isApprovePending, setIsApprovePending] = useState<boolean>(false)
  const [isRejectPending, setIsRejectPending] = useState<boolean>(false)
  const [isCancelPending, setIsCancelPending] = useState<boolean>(false)
  const [documentSidebarOpen, setDocumentSidebarOpen] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [policySidebarOpen, setPolicySidebarOpen] = useState(false)

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  useEffect(() => {
    setCategoryName(values.balance.policy.category?.name || '')
  }, [])

  const { data: policy, isLoading: isPolicyLoading } = useGetTimeOffPolicy(
    policySidebarOpen ? values.balance.policy.id : undefined,
  )

  const attachment = initialValues.attachment as FileInterface | undefined

  const isApproved = values.approval_status?.id === ApprovalStatuses.Approved

  const canViewComments = useMemo(
    () => values.field_options?.actions?.includes('access_comments'),
    [],
  )
  const canEdit = canEditEmployeeTimeOffRequest(values)
  const canApprove = canApproveEmployeeTimeOffRequest(values)
  const canReject = canRejectEmployeeTimeOffRequest(values)
  const canCancel = canCancelEmployeeTimeOffRequest(values)
  const canViewOverlappingRequests =
    values.field_options?.actions?.includes(EntityPermissions.ViewOverlappingRequests) &&
    values.field_options?.permissions?.includes(
      PermissionTypes.ViewOverlappingTimeOffRequests,
    )
  const canSeeDummyCancel = !canCancel && isApproved
  const canViewChangelog = values.field_options?.permissions?.includes(
    PermissionTypes.ViewChangelogTimeoffRequest,
  )

  const { data: overlappingRequests } = useTimeOffOverlappingRequests(
    canViewOverlappingRequests ? values.id : undefined,
  )

  const commentsApi = getTimeOffRequestCommentsAPI(values.id)
  const getComments = commentsApi.useGetComments(false, !canViewComments)

  const isLoading = String(values.id) !== params.id
  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useTimeOffRequestApprovals(employeeId, values.id, isLoading)

  const { data: nextRequest, isLoading: isLoadingNextRequest } = useNextTimeOffRequest()
  const onApprove = async () => {
    const response = await approveTimeOffRequest(employeeId, values.id)
    reset({
      ...response.data,
      field_options: { ...response.data.field_options!, actions: [] },
    })
    setSuccessPopupOpen(true)
    employeeTimeOffRequests('requests')
      .get({ id, employeeId })
      .then(res => {
        reset(res.data)
      })
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectTimeOffRequest(employeeId, values.id, rejectionComment)
      reset(response.data)
      setSuccessPopupOpen(true)
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectPending(false)
      if (canViewComments) {
        getComments.refetch()
      }
    }
  }

  const onCancel = async () => {
    setIsCancelPending(true)

    try {
      const response = await cancelTimeOffRequest(employeeId, values.id)
      reset(response.data)
      setSuccessPopupOpen(true)
    } finally {
      refetchApproval()
      setIsCancelPending(false)
    }
  }

  const anyPending = isApprovePending || isRejectPending || isCancelPending
  const showNextRequest = !!nextRequest?.id || isLoadingNextRequest

  return (
    <>
      <PageBody>
        <Box mb="s-16" maxWidth={512}>
          <MoreBar>
            <ApproveButton
              submit={onApprove}
              onBeforeSubmit={() => setIsApprovePending(true)}
              onAfterSubmit={() => {
                setIsApprovePending(false)
                refetchApproval()
              }}
              onSubmitFailed={handleError}
              statusFieldName="approval_status"
              isVisible={canApprove}
            />
            {canReject && (
              <MoreBar.Action
                onClick={() => setRejectDialogOpen(true)}
                useIcon={CrossSmall}
                variant="negative"
                pending={isRejectPending}
                disabled={anyPending}
              >
                Reject
              </MoreBar.Action>
            )}
            {canCancel && (
              <MoreBar.Action
                onClick={onCancel}
                useIcon={CrossSmall}
                variant="negative"
                pending={isCancelPending}
                disabled={anyPending}
              >
                Cancel
              </MoreBar.Action>
            )}
            {canSeeDummyCancel && (
              <Tooltip
                text="Only the approver or HR can cancel a request once it is approved"
                placement="right"
              >
                <MoreBar.Action useIcon={CrossSmall} variant="negative" disabled>
                  Cancel
                </MoreBar.Action>
              </Tooltip>
            )}
            {canEdit && (
              <EditButton
                route={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                  employeeId,
                  id,
                })}
                tooltip={
                  values.approval_status?.id === 'approved'
                    ? 'Requests cannot be edited once they are approved - if you would like to change your request, please ask the approver to cancel it and submit a new request'
                    : undefined
                }
              />
            )}
            {!!values.from_date_time && (
              <MoreBar.Action
                useIcon="CalendarDate"
                onClick={() =>
                  navigateTo(
                    pathToUrl(
                      ROUTES.FORMS.EMPLOYEE.TIME_OFF.TEAM_CALENDAR,
                      {
                        id: employeeId,
                      },
                      getCalendarPeriodQueryParams(values.from_date_time),
                    ),
                  )
                }
              >
                Show team calendar
              </MoreBar.Action>
            )}
            {canViewChangelog && (
              <MoreBar.Action
                use={InternalLink}
                useIcon={<Icon name="Time" size={16} />}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.CHANGELOG, params)}
              >
                Changelog
              </MoreBar.Action>
            )}
            <MoreBar.Action
              onClick={() => setPolicySidebarOpen(true)}
              useIcon={<Icon name="InfoOutline" size={16} />}
            >
              Policy details
            </MoreBar.Action>
            {canViewComments && (
              <MoreBar.Action
                useIcon={<Icon name="Chat" size={16} />}
                onClick={() => {
                  setCommentsSidebarOpen.current(true)
                }}
              >
                Comments
              </MoreBar.Action>
            )}
          </MoreBar>
        </Box>

        {overlappingRequests != null && overlappingRequests.length > 0 ? (
          <ActionWidget
            title="There seems to be an overlay with other member from this team."
            text="Please check the team calendar to verify your teams capacity planning is aligned."
            mb="s-16"
          >
            <Button
              onClick={() =>
                values.from_date_time
                  ? navigateTo(
                      pathToUrl(
                        ROUTES.FORMS.EMPLOYEE.TIME_OFF.TEAM_CALENDAR,
                        {
                          id: employeeId,
                        },
                        {
                          employee_id: uniq([
                            employeeId,
                            ...overlappingRequests.map(req => req.employee.id),
                          ]).join(','),
                          ...getCalendarPeriodQueryParams(values.from_date_time),
                        },
                      ),
                    )
                  : undefined
              }
              variant="secondary"
              size="sm"
              useIcon={ArrowSend}
            >
              View conflicts
            </Button>
          </ActionWidget>
        ) : null}

        <VStack gap="s-16">
          {approvalSteps === undefined || approvalSteps?.length ? (
            <ApprovalFlow
              isLoading={isApprovalLoading || isApprovePending}
              steps={approvalSteps || null}
              onViewRejectionReasonClick={
                canViewComments
                  ? () => {
                      setCommentsSidebarOpen.current(true)
                    }
                  : undefined
              }
            />
          ) : null}

          <FormPreview data={initialValues}>
            <Group>
              {initialValues.request_type_balance ? (
                <FormPreview.Item<EmployeeTimeOffRequestInterface>
                  title="Special request type"
                  field="request_type_balance.request_type.name"
                />
              ) : null}
              <FormPreview.Item<EmployeeTimeOffRequestInterface>
                title="Absence start"
                insert={request => {
                  if (request.unit.id === 'day') {
                    return `${formatDate(utcToLocalDate(request.from_date_time!))}, ${
                      request.from_time_period?.name
                    }`
                  }
                  return formatRequestDate(request.from_date_time!, 'hour')
                }}
              />
              <FormPreview.Item<EmployeeTimeOffRequestInterface>
                title="Absence end"
                insert={request => {
                  if (request.unit.id === 'day') {
                    return `${formatDate(utcToLocalDate(request.to_date_time!))}, ${
                      request.to_time_period?.name
                    }`
                  }
                  return formatRequestDate(request.to_date_time!, 'hour')
                }}
              />
              <FormPreview.Details title="Note" field="note" />
              <FormPreview.Item<EmployeeTimeOffRequestInterface>
                title="Attachment"
                insert={request => {
                  const requestAttachment = request.attachment as FileInterface

                  if (requestAttachment) {
                    return (
                      <Tooltip text={requestAttachment.name} placement="bottom">
                        <IconButton
                          onClick={() => setDocumentSidebarOpen(true)}
                          useIcon={Document}
                          color={Token.color.blue}
                          size={16}
                        />
                      </Tooltip>
                    )
                  }
                  return '-'
                }}
              />
              <FormPreview.Item<EmployeeTimeOffRequestInterface>
                title="Duration"
                field="total_duration"
                insert={request =>
                  request.total_duration
                    ? pluralize(
                        unitToLabel[initialValues.unit!.id],
                        request.total_duration,
                        true,
                      )
                    : '-'
                }
              />
              {values.unit.id === 'day' && (
                <FormPreview.Item<EmployeeTimeOffRequestInterface>
                  title="Non-working days"
                  insert={request =>
                    request.total_duration && request.duration
                      ? pluralize(
                          unitToLabel[initialValues.unit!.id],
                          request.total_duration - request.duration,
                          true,
                        )
                      : '-'
                  }
                />
              )}
              <FormPreview.Item<EmployeeTimeOffRequestInterface>
                title="Balance deduction"
                field="duration"
                insert={request =>
                  request.duration
                    ? pluralize(
                        unitToLabel[initialValues.unit!.id],
                        request.duration,
                        true,
                      )
                    : '-'
                }
              />
            </Group>
          </FormPreview>
          <FormPreview data={initialValues}>
            <Group>
              <FormPreview.Item
                title="Submitted on"
                field="requested_on"
                type="dateTime"
              />
            </Group>
          </FormPreview>

          {canViewComments ? (
            <CommentsSection
              api={commentsApi}
              setSidebarOpen={setCommentsSidebarOpen}
              disableTodolistFeature
            />
          ) : null}
        </VStack>
      </PageBody>

      {attachment && documentSidebarOpen && (
        <PreviewDocumentSidebar
          file={attachment}
          onClose={() => setDocumentSidebarOpen(false)}
        />
      )}

      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectPending}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Box>
            <Text mb="s-16" display="inline-block" color={Color.GREY_TONE_50}>
              Kindly let the employee know why you are rejecting this request
            </Text>
            <Input
              label="Add reason here"
              value={rejectionComment}
              onChange={e => setRejectionComment(e.currentTarget.value)}
            />
          </Box>
        }
        yesMessage="Reject"
        noMessage="Go back"
      />

      <StatusPopup
        variant="success-result"
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
      >
        <StatusPopup.Title>
          Request {values.approval_status?.name || 'Updated'}
        </StatusPopup.Title>
        <StatusPopup.Description>
          You can {showNextRequest ? 'review the next request or' : 'now'} go back to the
          requests table
        </StatusPopup.Description>
        <StatusPopup.Actions>
          {showNextRequest && (
            <Button
              elevated
              pending={isLoadingNextRequest}
              onClick={() => {
                queryClient.invalidateQueries(`${API.TIME_OFF}/requests/next`)
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                    id: nextRequest?.id,
                    employeeId: `${nextRequest?.employee?.id}`,
                  }),
                )
              }}
            >
              Review next request
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.ANY, { id: employeeId }),
              )
            }
          >
            Go to Requests Table
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>

      <SideBar
        title={
          <Header variant="item">
            <Header.Title>{values.balance.policy.name}</Header.Title>
            <Header.Description>Time Off Policy</Header.Description>
          </Header>
        }
        isOpen={policySidebarOpen}
        onClose={() => setPolicySidebarOpen(false)}
      >
        <VStack space="s-16" mt="-s-16">
          {policy ? (
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, { id: policy.id })}
              target="_blank"
              useEndIcon="ArrowThinRight"
            >
              Open in new tab
            </ActionButton>
          ) : (
            <ButtonSkeleton width={128} />
          )}
          <Cell>
            <VStack space="s-12">
              <H6 color={Token.color.greyTone50}>Policy summary</H6>
              {isPolicyLoading || !policy ? (
                <>
                  <Skeleton width={300} />
                  <Skeleton width={256} />
                </>
              ) : (
                <List variant="compact" space="s-16">
                  {getAccrualPeriodInfoString(policy) && (
                    <List.Item useIcon="Dot">
                      {getAccrualPeriodInfoString(policy)}
                    </List.Item>
                  )}
                  <List.Item useIcon="Dot">
                    {getBalanceClosingPeriodInfoString(policy)}
                  </List.Item>
                  {getCarryOverInfoString(policy) && (
                    <List.Item useIcon="Dot">{getCarryOverInfoString(policy)}</List.Item>
                  )}
                  {!!policy.maximum_balance && (
                    <List.Item useIcon="Dot">
                      Maximum balance is {pluralize('day', policy.maximum_balance, true)}
                    </List.Item>
                  )}
                  {getBalanceRoundingLogicInfoString(policy) && (
                    <List.Item useIcon="Dot">
                      {getBalanceRoundingLogicInfoString(policy)}
                    </List.Item>
                  )}
                </List>
              )}
            </VStack>
          </Cell>
          <Cell>
            <VStack space="s-12">
              <H6 color={Token.color.greyTone50}>Approver instructions</H6>
              {isPolicyLoading ? (
                <>
                  <Skeleton width={300} />
                  <Skeleton width={256} />
                </>
              ) : (
                <Text>{policy?.request_instructions}</Text>
              )}
            </VStack>
          </Cell>
          <Group>
            <FormPreview data={policy}>
              <FormPreview.Item<TimeOffPolicyInterface>
                title="Category"
                field="category.name"
                loading={isPolicyLoading}
                insert={data => {
                  return (
                    <HStack space="s-4" align="center">
                      <Icon name={policyCategoryToIcon(data.category.id)} size={15} />
                      <Text>{data.category.name}</Text>
                    </HStack>
                  )
                }}
              />
              <FormPreview.Item<TimeOffPolicyInterface>
                title="Guide"
                loading={isPolicyLoading}
                insert={data =>
                  data.details_url ? (
                    <IconButton
                      use={Link}
                      color={Token.color.blue}
                      useIcon="LinkExternal"
                      href={data.details_url}
                      target="_blank"
                    />
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item<TimeOffPolicyInterface>
                title="Owner"
                field="owner"
                loading={isPolicyLoading}
                insert={data => <UserWithAvatar {...data.owner} />}
              />
            </FormPreview>
          </Group>
        </VStack>
      </SideBar>
    </>
  )
}

export default Preview
